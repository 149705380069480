<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="합동점검 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  required
                  :editable="editable"
                  label="합동점검명"
                  name="jointInspectionName"
                  v-model="data.jointInspectionName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  range
                  label="점검기간"
                  name="data.jointDts"
                  v-model="data.jointDts">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-textarea
                  :editable="editable"
                  :rows="1"
                  label="내용"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-table
            ref="inGrid"
            title="내부참석자 목록"
            :editable="editable"
            :columns="inGrid.columns"
            :gridHeight="gridHeight"
            :data="data.innerList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="내부참석자를 추가하세요."
            rowKey="userId"
            selection="multiple"
          >
          </c-table>
        </div>  
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-table
            ref="vendorGrid"
            title="참가 협력업체"
            :editable="editable"
            :columns="vendorGrid.columns"
            :gridHeight="gridHeight"
            :data="data.vendorList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="참가 협력업체가 없습니다."
            rowKey="vendorCd"
            selection="multiple"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-table
            ref="outGrid"
            title="외부참석자 목록"
            :editable="editable"
            :columns="outGrid.columns"
            :gridHeight="gridHeight"
            :data="data.outerList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="외부참석자를 추가하세요."
            rowKey="saiJointOuterUserId"
            selection="multiple"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <c-table
            ref="itemGrid"
            title="점검항목"
            :editable="editable"
            :columns="itemGrid.columns"
            :gridHeight="gridHeight2"
            :data="data.itemResultList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="점검항목을 추가하세요"
            rowKey="jointInspectionResultId"
            selection="multiple"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 q-mt-md">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="합동점검 관련 첨부파일">
          </c-upload>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'dataCheckInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiJointInspectionId: '',
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SOP_CONSOLIDATION_CHECK',
        taskKey: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
    contentHeight: null,
  },
  computed: {
    disabled() {
      return this.popupParam.saiJointInspectionId ? false : true;
    },
  },
  watch: {
    'count'() {
      this.getDetail();
    },
  },
  data() {
    return {
      gridHeight: '250px',
      gridHeight2: '350px',
      inGrid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 80px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            align: 'left',
            style: 'width: 200px',
          },
        ],
      },
      outGrid: {
        columns: [
          {
            name: 'company',
            field: 'company',
            label: '소속',
            type: 'text',
            align: 'center',
            style: 'width: 80px',
            required: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            style: 'width: 80px',
            type: 'text',
            required: true,
            align: 'center',
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            type: 'text',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            align: 'left',
            style: 'width: 200px',
          },
        ],
      },
      vendorGrid: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '협력업체 명',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            align: 'left',
            style: 'width: 60%',
          },
        ],
      },
      itemGrid:  {
        columns: [],
      },
      editable: true,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      deleteInUrl: '',
      deleteOutUrl: '',
      deleteVendorUrl: '',
      deleteResultUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      data: {
        saiJointInspectionId: '',  // 합동점검 일련번호
        plantCd: '',  // 사업장 코드
        jointInspectionName: '',  // 합동점검명
        jointStartDt: '',  // 점검시작일
        jointEndDt: '',  // 점검종료일
        jointDts: [],
        remark: '',  // 내용
        jointCompleteFlag: '',  // 완료여부
        innerList: [],
        outerList: [],
        vendorList: [],
        itemResultList: [],
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = false;
      this.detailUrl = selectConfig.sai.joint.get.url;
      this.setHeader();
      this.getDetail();
    },
    setHeader() {
      this.$comm.getComboItems('JOINT_RESULT_CD').then(_result => {
        this.itemGrid.columns = [
          {
            name: 'jointItemName',
            field: 'jointItemName',
            label: '점검항목',
            align: 'left',
            type: 'textarea',
            style: 'width:30%',
            sortable: false,
          },
          {
            required: true,
            name: 'jointResultCd',
            field: 'jointResultCd',
            label: '점검결과',
            setHeader: true,
            align: 'center',
            style: 'width:10%',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            setHeader: true,
            name: 'jointContents',
            field: 'jointContents',
            label: '비고',
            align: 'left',
            type: 'textarea',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            type: 'custom',
            sortable: false
          },
        ]
      });
    },
    getDetail() {
      if (this.popupParam.saiJointInspectionId) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {saiJointInspectionId: this.popupParam.saiJointInspectionId};
        this.$http.request((_result) => {
          this.data = _result.data;
          this.$set(this.attachInfo, 'taskKey', _result.data.saiJointInspectionId)
        },);
      }
    },
  }
};
</script>
